import React, { useState } from 'react';
import './event-view.css';
import PickList from '../components/event-view/pick-list'
import SelectList from '../components/event-view/select-list'

function EventView() {
  const [isVisible, setVisible] = useState(true);
  const [itemToAdd, setItemToAdd] = useState({});

  return (
    <React.Fragment>
      <div className={`eventView ${isVisible && 'active'}`}>
        <SelectList itemToAdd={itemToAdd}/>
        <PickList setItemToAdd={setItemToAdd}/>
      </div>
      <button className={`eventViewButton ${isVisible && 'active'}`} onClick={() => setVisible(!isVisible)}>{isVisible ? '<' : '+'}</button>
    </React.Fragment>
  );
}

export default EventView;