import React from 'react';
// import logo from './logo.svg';
// import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import './App.css';
import './views/main';
import Main from './views/main';

function App() {
  return (
    <Main />

  );
}

    // <Router>
    //   <div>
    //     <Header />

    //     <Route exact path="/" component={Home} />
    //     <Route path="/about" component={About} />
    //     <Route path="/topics" component={Topics} />
    //   </div>
    // </Router>
// function Home() {
//   return <h2>Home</h2>;
// }

// function About() {
//   return <h2>About</h2>;
// }
// interface ITopic {
//   match: any;
//   // any other props that come into the component
// }

// function Topic({ match }:ITopic) {
//   return <h3>Requested Param: {match.params.id}</h3>;
// }



// function Topics({ match }:ITopic) {
//   return (
//     <div>
//       <h2>Topics</h2>

//       <ul>
//         <li>
//           <Link to={`${match.url}/components`}>Components</Link>
//         </li>
//         <li>
//           <Link to={`${match.url}/props-v-state`}>Props v. State</Link>
//         </li>
//       </ul>

//       <Route path={`${match.path}/:id`} component={Topic} />
//       <Route
//         exact
//         path={match.path}
//         render={() => <h3>Please select a topic.</h3>}
//       />
//     </div>
//   );
// }

// function Header() {
//   return (
//     <ul>
//       <li>
//         <Link to="/">Home</Link>
//       </li>
//       <li>
//         <Link to="/about">About</Link>
//       </li>
//       <li>
//         <Link to="/topics">Topics</Link>
//       </li>
//     </ul>
//   );
// }

export default App;
