import React, { useState, useEffect, useRef } from 'react';
import './item.css';

interface IItem{
  updateItem?: ({}:any)=> void
  setItemToAdd?: ({})=>{}
  item:{kcal:string, amount:number, name:string, id:null}
}

const Item: React.SFC<IItem> = (props) => {
  const [item, setItem] = useState({kcal:"", amount:0, name:"", id:null});
  const [calculatedKcal, setCalculatedKcal] = useState("0");
  const [showBody, setShowBody] = useState(false);
  const bodyElement = useRef<HTMLDivElement>(document.createElement("div"));

  useEffect(()=>{
    setItem({...props.item})
  }, [props.item])

  useEffect(()=>{
    bodyElement.current.style.maxHeight = showBody ? bodyElement.current.scrollHeight + "px" : null;
  }, [showBody])

  useEffect(()=>{
    if(item){
      var calculatedKcal = (parseFloat(item.kcal) * (item.amount / 100))
      var calculatedKcalDisplay = isNaN(calculatedKcal) ?
        '0'
        :
        calculatedKcal < 1 ? calculatedKcal.toFixed(2) : calculatedKcal.toFixed(0)
      setCalculatedKcal(calculatedKcalDisplay)
    }
  }, [item])

  useEffect(()=>{
    props.updateItem && props.updateItem(item)
  }, [item])

  return (
    <div className="listItem" >
      <div className="header" onClick={()=>setShowBody(!showBody)}>
        <div className="headerText">{item && item.name}</div>
        <div>{showBody ? '-' : '+'}</div>
      </div>
      <div className="body" ref={bodyElement}>
        <div>{item && item.kcal}kcal/100gr</div>
        <div className="amount">
        {item && <input type="number" className="amount input medium" onChange={(e:any)=>setItem({...item, amount:parseInt(e.currentTarget.value)} )} value={item.amount || 0}/>}gr = {calculatedKcal} kcal
        </div>
        {item && !props.setItemToAdd && <button type="button" className="button ok" onClick={()=>setItem({...item, id:null})}>Tabort</button>}
        {item && props.setItemToAdd && <button type="button" className="button ok" onClick={() => props.setItemToAdd && props.setItemToAdd({...item, dispatchTime: Date.now()})}>Lägg till</button>}
      </div>
    </div>
  );
}

export default Item;



// import React, { useState, useEffect, useRef } from 'react';
// import './item.css';

// function Item(props:any) {
//   const { item } = props
//   const [amount, setAmount] = useState(0);
//   const [calculatedKcal, setCalculatedKcal] = useState("0");
//   const [showBody, setShowBody] = useState(false);
//   const bodyElement = useRef<HTMLDivElement>(document.createElement("div"));

//   useEffect(()=>{
//     bodyElement.current.style.maxHeight = showBody ? bodyElement.current.scrollHeight + "px" : null;
//   }, [showBody])

//   useEffect(()=>{
//     var calculatedKcal = (parseFloat(item.kcal) * (amount / 100))
//     var calculatedKcalDisplay = isNaN(calculatedKcal) ?
//       '0'
//       :
//       calculatedKcal < 1 ? calculatedKcal.toFixed(2) : calculatedKcal.toFixed(0)
//     setCalculatedKcal(calculatedKcalDisplay)
//   }, [amount])

//   useEffect(()=>{
//     setAmount(item.amount)
//   }, [item])

//   useEffect(()=>{
//     item.amount = amount
//   }, [amount])
  
//   useEffect(()=>{
//     props.updateItem && props.updateItem(item)
//   }, [amount])

//   return (
//     <div className="listItem" >
//       <div className="header" onClick={()=>setShowBody(!showBody)}>
//         <div className="headerText">{item.name}</div>
//         <div>{showBody ? '-' : '+'}</div>
//       </div>
//       <div className="body" ref={bodyElement}>
//         <div>{item.kcal}kcal/100gr</div>
//         <div className="amount">
//           <input type="number" className="amount input medium" onChange={(e:any)=>setAmount(parseInt(e.currentTarget.value))} value={item.amount || 0}/>gr = {calculatedKcal} kcal
//         </div>
//         {props.removeItem && <button type="button" className="button ok" onClick={()=>props.removeItem(item.dispatchTime)}>Tabort</button>}
//         {props.setItemToAdd && <button type="button" className="button ok" onClick={() => props.setItemToAdd({...item, dispatchTime: Date.now()})}>Lägg till</button>}
//       </div>
//     </div>
//   );
// }

// export default Item;
