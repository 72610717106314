import React, { useState, useEffect } from 'react';
import './select-list.css';
import Item from './item'

function SelectList(props: any) {
  const [selected, setSelected] = useState([{}]);
  const [totalKcal, setTotalKcal] = useState(0);

  useEffect(() => {
    const filteredSelected = selected.filter((x: any) => x.dispatchTime) // remove empty items
    setSelected([...filteredSelected, props.itemToAdd])
  }, [props.itemToAdd])

  function calcKcal(item: any) {
    if(item.amount){
      const kcal = parseFloat(item.kcal)
      const amount = item.amount / 100
      const total = (kcal * amount)
      return total
    }
    return 0
  }

  useEffect(() => {
    const sum = selected.reduce((a: number, b: any) => a + calcKcal(b), 0)
    setTotalKcal(sum)
  }, [selected])

  function updateItem(item: any) {
    let newSelected = [...selected]
    // update values
    newSelected.map((x: any) => {
      if (x.dispatchTime === item.dispatchTime) {
        x.amount = item.amount
        x.id = item.id
      }
    })
    // remove item if id is null
    newSelected = newSelected.filter((x: any) => x.id)
    setSelected([...newSelected])
  }

  return (
    <React.Fragment>
      <div>
        <h2>Måltid - {isNaN(totalKcal) ? 0 : totalKcal.toFixed(0)} kcal</h2>
      </div>
      <div className="selectedList">
        {
          selected.length > 0 &&
          selected.map((item: any) => {
            return item.name && <Item key={parseInt(item.dispatchTime)} item={item} updateItem={updateItem} />
          })
        }
      </div>
    </React.Fragment>
  );
}

export default SelectList;
