import React from 'react';
import './main.css';
import EventView from './event-view';

function Main() {
    return (

        <React.Fragment>
            <div>hejmain</div>
            <EventView />
        </React.Fragment>
    );
}

export default Main;