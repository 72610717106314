import React, { useState, useEffect, useCallback } from 'react';
import './pick-list.css';
import Item from './item'

function PickList(props:any) {
  const [selection, setSelection] = useState([{}]);
  const [searchText, setSearchText] = useState("ost");

  // create an abort controller for fetch to be able to cancel it
  const controller = new AbortController();

  async function fetchSearchResult() {
    fetch(
      `https://api.livsmedel.lindstrom.li/?Search=${searchText}`,
      { signal: controller.signal }
    )
    .then(result => result.json())
    .then(result=>setSelection(result))
    .catch(err=>{
      if (err.name !== "AbortError") {
            // handle error
          }
    })
  }

  useEffect(() => {
      searchText !== "" && fetchSearchResult();
      return () => {
        // aborting request when cleaning up effect
        controller.abort();
        setSelection([{}]);
      };
    },[searchText]);

  return (
    <React.Fragment>
        <input className="search input medium" type="search" placeholder="Sök efter måltid eller aktivitet" onChange={(e => setSearchText(e.target.value))} />
        <div className="pickList">
          {
            selection.length > 0 && 
            Object.entries(selection[0]).length !== 0 && 
            selection.map((item: any) => <Item key={item.id} item={item} setItemToAdd={props.setItemToAdd}/>)
          }
        </div>
    </React.Fragment>
  );
}

export default PickList;